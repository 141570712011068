@media (max-width: $screen-lg) {
    // lg-md view
    .contacts-block { 
        margin: 0 0 28px;
        .contacts-block-holder { 
            .map {
                margin: 0 0 51px;
            }

            .contacts-data { 
                .contacts-data-holder { 
                    .places { 
                        width: calc(100% + 20px);
                        margin-left: -10px;
                        .place {
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .contacts-block {
        margin: 0 0 20px;
        .contacts-block-holder { 
            .map {
                height: 400px;
                margin: 0 0 30px;
            }

            .contacts-data { 
                .contacts-data-holder { 
                    .places { 
                        width: calc(100% + 10px);
                        margin-left: -5px;
                        .place {
                            width: 33.333%;
                            padding: 0 5px;
                            margin: 0 0 14px;
                            .place-holder { 
                                .heading {
                                    margin: 0 0 27px;
                                    a {

                                    }
                                }

                                .address { 
                                    margin: 0 0 17px;
                                    .name {
                                        margin: 0 0 9px;
                                        a {
                                            font-size: 12px;
                                        }
                                    }

                                    .contacts-info { 
                                        .info {
                                            a {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
