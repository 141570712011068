@media (max-width: $screen-lg) {
    // lg-md view
    .news-block { 
        padding: 0 0 16px;
        .news-block-holder { 
            .news-list-block { 
                padding: 38px 40px 39px;
            }
        }
    }

    .news-list-block.standalone {
        margin: 0 0 65px;
        .news-list-block-holder { 
            .news-headings-list { 
                padding-left: 31px;
                padding-top: 33px;
            }
        }
    }
    .news-list-block { 
        .news-list-block-holder { 
            .spotlight { 
                width: 45%;
            }

            .news-headings-list { 
                width: 55%;
                padding-left: 107px;
                padding-top: 35px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .news-block { 
        padding: 0 0 32px;
        .news-block-holder { 
            .news-list-block {
                padding: 30px 30px 30px;
            }
        }
    }

    .news-list-block.standalone {
        margin: 0 0 50px;
        .news-list-block-holder { 
            .spotlight {
                width: 41%;
            }
            .news-headings-list { 
                width: 59%;
                padding-left: 59px;
                padding-top: 29px;
            }
        }
    }
    .news-list-block {
        .news-list-block-holder { 
            .spotlight { 
                width: 38%;
            }

            .news-headings-list { 
                width: 62%;
                padding-left: 61px;
                padding-top: 30px;                    
                .news-headings-list-holder { 
                    .news-heading-item { 
                        margin: 0 0 31px;
                        .news-heading-item-holder {
                            .name {
                                font-size: 16px;
                            }

                            .date {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .news-item { 
        .news-item-holder { 
            .news-info { 
                min-height: 297px;
                .date { 
                    font-size: 14px;
                }

                .name {
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    margin: 0 0 21px;
                    a {

                    }
                }

                .text {
                    font-size: 14px;
                }
            }

            .action { 

            }
        }
    }
}
