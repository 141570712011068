.news-detail-block {
    display: inline-block;
    width: 100%;
    margin: 15px 0 52px;
    .news-detail-block-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 43px;
            h1, .h1 {

            }
        }

        >.date {
            display: inline-block;
            width: 100%;
            color: $gray;
            margin: 0 0 22px;
        }

        .news-main-image{
            display: inline-block;
            width: 100%;
            height: 540px;
            margin: 0 0 40px;
            .news-main-image-holder {
                display: inline-block;
                width: 100%;
                height: 100%;
                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.news-detail {
    display: inline-block;
    width: 100%;
    .news-detail-holder {
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        >.text {
            float: left;
            width: calc(100% - 625px);
            box-sizing: border-box;
            padding: 0 15px;
           >.text-holder {
                display: inline-block;
                width: 100%;
                h3, .h3 {
                    margin: 0 0 21px;
                }

                p {
                    color: $gray;
                    strong {
                        font-weight: 500;
                    }
                }  

                p.accent {
                    color: $gray-base;
                }

                ul, ol {
                    color: $gray;
                }

                hr {
                    border: none;
                    margin: 14px 0 27px;
                }

                img {
                    width: 100%;
                    height: auto;
                }
           } 
        }

        .side-bar {
            float: left;
            width: 625px;
            box-sizing: border-box;
            padding: 0 15px;
            .side-bar-holder {
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.other-news { 
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
	.other-news-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 27px 30px 28px;
		.heading { 
            display: inline-block;
            width: 100%;
            position: relative;
            padding: 0 0 11px;
            margin: 0 0 32px;
            &:after {
                content: "";
                display: inline-block;
                width: 94px;
                height: 0;
                border-top: 1px solid $brand-primary;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
			h4, .h4 { 

			}
		}

		.news-list { 
            display: inline-block;
            width: 100%;
			.news-heading-item { 
                display: inline-block;
                width: 100%;
                margin: 0 0 23px;
                &:last-of-type {
                    margin: 0;
                }
                .news-heading-item-holder { 
                    display: inline-block;
                    width: 100%;
                    .name { 
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 120%;
                        margin: 0 0 14px;
                        a { 
                            color: $gray-base;
                            display: inline-block;
                            position: relative;
                            .icon {
                                position: absolute;
                                right: -10px;
                                top: 41%;
                                transform: translateX(-50%);
                                z-index: 1;
                                svg {
                                    width: 17px;
                                    height: 11px;
                                }
                            }
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }

                    .date { 
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 140%;
                        color: $gray;
                    }
                }
            }
		}
	}
}

.socials { 
    display: inline-block;
    width: 100%;
	.socials-holder { 
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
		.text { 
            max-width: 55%;
			.text-holder { 

				.heading { 
                    margin: 0 0 12px;
					h4, .h4 { 

					}
				}

				.note { 
                    color: $gray;
				}  
			}
		}

		.links { 
            max-width: 45%;
			.links-holder { 
                margin: 0 -10px;
				.link { 
                    width: 45px;
                    height: 45px;
                    float: left;
                    margin: 0 10px;
					a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
						svg { 
                            width: 100%;
                            height: auto;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/news_detail";
@import "../../media/mobile/includes/common/news_detail";
