@media (max-width: $screen-sm) {
    // sm-view
    .policy-text-block { 
        margin: 0 0 30px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
