.news-block { 
    display: inline-block;
    width: 100%;
    background-color: $bg-secondary;
    padding: 0 0 38px;
	.news-block-holder { 
        display: inline-block;
        width: 100%;
        .news-list-block { 
            padding: 38px 40px 33px;
        }
	}
}

.news-list-block.standalone {
    margin: 0 0 65px;
    .news-list-block-holder { 
        .news-headings-list { 
            padding-left: 71px;
        }
    }
}
.news-list-block { 
    display: inline-block;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    .news-list-block-holder { 
        display: inline-block;
        width: 100%;
        .spotlight { 
            width: 34%;
            float: left;
            .spotlight-holder { 

            }
        }

        .news-headings-list { 
            width: 66%;
            float: left;
            box-sizing: border-box;
            padding-left: 98px;
            padding-top: 33px;
            .news-headings-list-holder { 
                display: inline-block;
                width: 100%;
                .news-heading-item { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 33px;
                    &:last-of-type {
                        margin: 0;
                    }
                    .news-heading-item-holder { 
                        display: inline-block;
                        width: 100%;
                        .name { 
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 120%;
                            margin: 0 0 9px;
                            a { 
                                color: $gray-base;
                                &:hover {
                                    color: $brand-primary;
                                }
                            }
                        }

                        .date { 
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 140%;
                            color: $gray;
                        }
                    }
                }
            }
        }
    }
}

.news-item { 
    display: inline-block;
    width: 100%;
    .news-item-holder { 
        display: flex;
        flex-direction: column;
        width: 100%;
        .news-info { 
            display: inline-block;
            width: 100%;
            flex-grow: 1;
            overflow: hidden;
            min-height: 294px;
            .date { 
                text-align: left;
                color: $gray;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                margin: 0 0 10px;
            }

            .name { 
                overflow: hidden;
                text-overflow: ellipsis;
                display: -moz-box;
                -moz-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-clamp: 3;
                box-orient: vertical;
                margin: 0 0 19px;
                a { 
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .text { 
                overflow: hidden;
                text-overflow: ellipsis;
                display: -moz-box;
                -moz-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                line-clamp: 5;
                box-orient: vertical;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                text-align: left;
                color: $gray;
            }
        }

        .action { 
            display: inline-block;
            width: 100%;
            .btn { 
                width: 280px;
                text-align: center;
            }
        }
    }
}

@import "../../media/tablet/includes/index/news";
@import "../../media/mobile/includes/index/news";
