@media (max-width: $screen-sm) {
    // sm-view
    .news-block { 
        padding: 0 0 9px;
        .news-block-holder { 
            .news-list-block {
                padding: 20px 20px 20px;
            }
        }
    }

    .news-list-block.standalone {
        margin: 0 0 15px;
        .news-list-block-holder { 
            .spotlight {
                width: 100%;
            }
            .news-headings-list { 
                width: 100%;
                padding-left: 0;
                padding-top: 0;
                .news-headings-list-holder { 
                    .news-heading-item { 
                        margin: 0 0 32px;
                    }
                }
            }
        }
    }
    .news-list-block {
        .news-list-block-holder { 
            .spotlight { 
                width: 100%;
                margin: 0 0 42px;
            }

            .news-headings-list { 
                width: 100%;
                padding: 0;
                .news-headings-list-holder { 
                    .news-heading-item { 
                        margin: 0 0 22px;
                        .news-heading-item-holder {
                            .name {
                                font-size: 14px;
                            }

                            .date {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .news-item { 
        .news-item-holder { 
            .news-info { 
                min-height: auto;
                .date {
                    font-size: 12px;
                }

                .name {
                    font-size: 18px;
                }

                .text {
                    font-size: 12px;
                }
            }

            
            .action {
                width: 100%;
                margin: 21px 0 0;
                .btn {
                    width: 100%;
                    padding: 13px 14px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
