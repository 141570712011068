.not-found-page { 
    display: inline-block;
    width: 100%;
    margin: 0 0 38px;
	.not-found-page-holder { 
        display: inline-block;
        width: 91.5%;
        padding: 41px 0 0;
		.desc { 
            width: calc(100% - 710px);
            float: left;
            padding: 113px 0 0;
			.desc-holder { 
                display: inline-block;
                width: 100%;
				.heading { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 29px;
					h1, .h1 { 

					}
				}

				.note { 
                    display: inline-block;
                    width: 70%;
                    color: $gray;
                    margin: 0 0 16px;
					p { 
                        margin: 0 0 15px;
					}
				}

				.links { 
                    display: inline-block;
                    width: 100%;
					.link { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 8px;
						a { 
                            color: $gray-base;
                            font-size: 18px;
                            line-height: 120%;
                            font-weight: 500;
                            &:hover {
                                color: $brand-primary;
                            }
							.text { 
                                float: left;
							}

							.icon { 
                                float: left;
                                margin-left: 10px;
								svg { 
                                    width: 17px;
                                    height: 11px;
                                    vertical-align: middle;
								}
							}
						}
					}
				}
			}
		}

		.four-o-four { 
            width: 710px;
            float: right;
			.four-o-four-holder { 
                display: inline-block;
                width: 100%;
				.image { 
                    display: inline-block;
                    width: 100%;
					svg { 
                        width: 100%;
                        height: auto;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/404";
@import "../../media/mobile/includes/common/404";
