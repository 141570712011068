.reviews-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 64px;
	.reviews-block-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 38px;
            h2, .h2 { 

            }
        }

        .reviews-list { 
            display: inline-block;
            width: 100%;
            .reviews-list-holder { 
                display: inline-block;
                width: calc(100% + 30px);
                margin-left: -15px;
                .review-item { 
                    width: 33.333%;
                    float: left;
                    box-sizing: border-box;
                    padding: 0 15px;
                    .review-item-holder { 
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 30px;
                        background-color: $bg-secondary;
                        .author { 
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin: 0 0 17px;
                            .image { 
                                width: 65px;
                                height: 65px;
                                border-radius: 50%;
                                overflow: hidden;
                                font-size: 28px;
                                background-color: $brand-primary;
                                color: white;
                                line-height: 65px;
                                text-align: center;
                                img { 
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .stats { 
                                width: calc(100% - 65px);
                                box-sizing: border-box;
                                padding-left: 15px;
                                margin: 4px 0 0;
                                .name { 
                                    width: 100%;
                                    font-size: 18px;
                                    font-weight: 500;
                                    line-height: 120%;
                                    text-align: left;
                                    margin: 0 0 5px;
                                }

                                .score { 
                                    width: 100%;
                                    .stars { 
                                        width: 100%;
                                        margin-left: -1px;
                                        &.stars-1 {
                                            .star:first-of-type {
                                                color: $brand-primary;
                                            }
                                        }
                                        &.stars-2 {
                                            .star:nth-of-type(1),
                                            .star:nth-of-type(2)  {
                                                color: $brand-primary;
                                            }
                                        }
                                        &.stars-3 {
                                            .star:nth-of-type(1),
                                            .star:nth-of-type(2),
                                            .star:nth-of-type(3)  {
                                                color: $brand-primary;
                                            }
                                        }
                                        &.stars-4 {
                                            .star:nth-of-type(1),
                                            .star:nth-of-type(2),
                                            .star:nth-of-type(3),
                                            .star:nth-of-type(4)  {
                                                color: $brand-primary;
                                            }
                                        }
                                        &.stars-5 {
                                            .star {
                                                color: $brand-primary;
                                            }
                                        }
                                        .star { 
                                            float: left;
                                            width: 18px;
                                            height: 17px;
                                            margin: 0 1.5px;
                                            color: $gray-light;
                                            svg { 
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .review-text { 
                            display: inline-block;
                            width: 100%;
                            .text { 
                                overflow: hidden;
                                width: 100%;
                                height: 281px;
                                text-overflow: ellipsis;
                                display: -moz-box;
                                -moz-box-orient: vertical;
                                display: -webkit-box;
                                -webkit-line-clamp: 15;
                                -webkit-box-orient: vertical;
                                line-clamp: 15;
                                box-orient: vertical;
                                p {
                                    color: $gray;
                                    font-size: 14px;
                                    line-height: 143%;
                                    font-weight: 400;
                                    margin: 0;
                                    &:not(:last-of-type) {
                                        margin: 0 0 17px;
                                    }
                                }
                            }
                        }

                        .action { 
                            display: inline-block;
                            width: 100%;
                            margin: 28px 0 0;
                            a { 
                                color: $gray;
                                &:hover {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }
            }
        }

        .actions { 
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 41px 0 0;
            .action { 

                .btn { 
                    width: 280px;
                    text-align: center;
                }
            }

            .pagination-container { 
                .pagination {
                    margin: 0;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/reviews";
@import "../../media/mobile/includes/common/reviews";
