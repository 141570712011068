@media (max-width: $screen-sm) {
    // sm-view
    .about-company-block { 
        margin: 0 0 38px;
        .about-company-block-holder { 
            .about-company { 
                margin: 0 0 27px;
                .about-company-holder {
                    display: inline-block; 
                    width: 100%;
                    margin-left: 0;
                    .logo {
                        padding: 0;
                        width: 100%;
                        height: 84vw;
                        margin: 0 0 10px;
                        .logo-holder { 
                            svg {
                                width: 195px;
                                height: auto;
                            }
                        }
                    }

                    .company-points {
                        padding: 0;
                        width: 100%;
                        .company-points-holder { 
                            .points { 
                                .point { 
                                    .point-holder {
                                        font-size: 12px;
                                        min-height: 83px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
