@media (max-width: $screen-sm) {
    // sm-view
    .not-found-page { 
        margin: 0 0 30px;
        .not-found-page-holder { 
            padding: 38px 0 0;
            display: flex;
            flex-direction: column-reverse;
            .desc { 
                width: 100%;
                padding: 42px 0 0;
                .desc-holder { 
                    .heading { 
                        margin: 0 0 21px;
                    }

                    .note {
                        width: 100%;
                        margin: 0 0 6px;
                        p {
                            margin: 0 0 17px;
                        }
                    }

                    .links { 
                        .link {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .four-o-four { 
                width: 100%;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
