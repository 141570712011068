@media (max-width: $screen-lg) {
    // lg-md view
    .not-found-page { 
        .not-found-page-holder { 
            width: 100%;
            .desc { 
                width: calc(100% - 477px);
                padding: 32px 0 0;
            }

            .four-o-four { 
                width: 477px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .not-found-page { 
        .not-found-page-holder { 
            .desc { 
                width: calc(100% - 392px);
                padding: 8px 0 0;
                .desc-holder { 
                    .heading { 

                    }

                    .note {
                        width: 96%;
                        margin: 0 0 20px;
                    }

                    .links { 
                        .link {
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .four-o-four { 
                width: 392px;
            }
        }
    }
}
