@media (max-width: $screen-sm) {
    // sm-view
    .news-detail {
        .news-detail-holder {
            >.text {
                margin: 0 0 18px;
            }
        }
    }
    
    .other-news { 
        background: none;
        margin: 0 0 41px;
        .other-news-holder { 
            padding: 0;
            .heading { 
                margin: 0 0 22px;
            }

            .news-list { 
                .news-heading-item { 
                    .news-heading-item-holder { 
                        .name { 
                            font-size: 12px;
                            margin: 0 0 4px;
                            a {
                                height: 16px;
                                .icon {
                                    top: 9%;
                                }
                            }
                        }

                        .date { 
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .socials { 
        background-color: transparent;
        .socials-holder { 
            padding: 0;
            .text {
                .text-holder {
                    .heading {
                        margin: 0 0 8px;
                    }
                }
            }

            .links { 
                .links-holder { 
                    margin: 0 -7.5px;
                    .link {
                        margin: 0 7.5px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
