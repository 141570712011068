@media (max-width: $screen-lg) {
    // lg-md view
    .news-detail {
        .news-detail-holder {
            width: calc(100% + 20px);
            margin-left: -10px;
            >.text {
                width: calc(100% - 471px);
                padding: 0 10px;
            }

            .side-bar {
                width: 471px;
                padding: 0 10px;
            }
        }
    }

    .other-news { 
        .other-news-holder { 
            padding: 30px 30px 30px;
            .heading { 

            }

            .news-list { 
                .news-heading-item { 
                    .news-heading-item-holder {
                        .name { 
                            margin: 0 0 7px;
                            a {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -moz-box;
                                -moz-box-orient: vertical;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                line-clamp: 1;
                                box-orient: vertical;
                                height: 25px;
                                .icon {
                                    top: 21%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .socials { 
        .socials-holder { 
            .text { 
                max-width: 75%;
            }

            .links {
                max-width: 25%;
                .links-holder { 
                    .link {
                        width: 35px;
                        height: 35px;
                        a {

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .news-detail-block {
        .news-detail-block-holder {
            .heading {
                margin: 0 0 32px;
            }

            .news-main-image{
                height: 400px;
                margin: 0 0 32px;
            }
        }
    }

    .news-detail {
        .news-detail-holder {
            width: 100%;
            margin: 0;
            >.text {
                width: 100%;
                padding: 0;
                margin: 0 0 7px;
                >.text-holder {
                    hr {
                        margin: 10px 0 5px;
                    }

                    img {
                        margin: 0 0 13px;
                    }
                }
            }

            .side-bar {
                width: 100%;
                padding: 0;
            }
        }
    }

    .other-news { 
        margin: 0 0 22px;
        .other-news-holder { 
            .heading { 

            }

            .news-list { 
                .news-heading-item { 
                    margin: 0 0 22px;
                    .news-heading-item-holder { 
                        .name {
                            font-size: 14px;
                            margin: 0 0 9px;
                            a {
                                height: 19px;
                            }
                        }

                        .date { 
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
