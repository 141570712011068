.contacts-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 32px;
	.contacts-block-holder { 
        display: inline-block;
        width: 100%;
		.map { 
            display: inline-block;
            width: 100%;
            height: 540px;
            margin: 0 0 61px;
			.map-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				>img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}

		.contacts-data { 
            display: inline-block;
            width: 100%;
			.contacts-data-holder { 
                display: inline-block;
                width: 100%;
				.places { 
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% + 30px);
                    margin-left: -15px;
					.place { 
                        float: left;
                        width: 25%;
                        box-sizing: border-box;
                        padding: 0 15px;
						.place-holder { 
                            display: inline-block;
                            width: 100%;
							.heading { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 38px;
                                a {
                                    display: flex;
                                    align-items: center;
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                    .icon {
                                        width: 18px;
                                        height: 18px;
                                        margin-right: 11px;
                                        color: $brand-primary;
                                        svg {
                                            width: 100%;
                                            height: auto;
                                        }
                                    }
                                    h3, .h3 { 
    
                                    }
                                }
                               
							}

							.address { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 29px;
								.name { 
                                    display: inline-block;
                                    width: 100%;
                                    font-size: 14px;
                                    line-height: 140%;
                                    margin: 0 0 12px;
									a { 
                                        color: $gray;
                                        &:hover {
                                            color: $brand-primary;
                                        }
									}
								}

								.contacts-info { 
                                    display: inline-block;
                                    width: 100%;
									.info { 
                                        display: inline-block;
                                        width: 100%;
										a { 
                                            display: flex;
                                            align-items: center;
                                            color: $gray-base;
                                            font-size: 18px;
                                            line-height: 120%;
                                            font-weight: 500;
                                            &:hover {
                                                color: $brand-primary;
                                            }
											.icon { 
                                                width: 18px;
                                                height: 18px;
                                                color: $brand-primary;
                                                margin-right: 10px;
												svg { 
                                                    width: 100%;
                                                    height: auto;
												}
											}

											.text {     

											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/contacts";
@import "../../media/mobile/includes/common/contacts";
