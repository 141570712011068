@media (max-width: $screen-sm) {
    // sm-view
    .payment-methods-block { 
        margin: 0 0 44px;
        .payment-methods-block-holder {
            .payment-methods { 
                width: 100%;
                margin: 0;
                .payment { 
                    width: 100%;
                    padding: 0;
                    margin: 0 0 10px;
                    a {
                        .icon {
                            margin: 0 0 20px;
                            .icon-holder {
                                width: 35px;
                                height: 35px;
                                svg {
                                    
                                }
                            }
                        }   

                        .text {
                            .text-holder {
                                .heading {
                                    margin: 0 0 9px
                                }

                                .desc { 
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
