@media (max-width: $screen-sm) {
    // sm-view
    .contacts-block {
        margin: 0 0 9px;
        .contacts-block-holder { 
            .map {
            }

            .contacts-data { 
                .contacts-data-holder { 
                    .places { 
                        width: 100%;
                        margin: 0;
                        .place {
                            width: 100%;
                            padding: 0;
                            margin: 0 0 23px;
                            .place-holder { 
                                .heading {
                                    margin: 0 0 16px;
                                }

                                .address { 
                                    margin: 0 0 17px;
                                    .name {
                                        margin: 0 0 6px;
                                        a {
                                            font-size: 10px;
                                        }
                                    }

                                    .contacts-info { 
                                        .info {
                                            a {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $screen-xs) {
    // xs-view
}
