@media (max-width: $screen-lg) {
    // lg-md view
    .about-company-block { 
        margin: 0 0 40px;
        .about-company-block-holder { 
            .about-company { 
                .about-company-holder { 
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    .logo {
                        padding: 0 10px;
                        width: calc(100% - 565px);
                    }

                    .company-points {
                        padding: 0 10px;
                        width: 565px;
                        .company-points-holder { 
                            .points { 
                                .point { 
                                    margin: 0 0 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .about-company-block { 
        margin: 0 0 40px;
        .about-company-block-holder { 
            .about-company { 
                margin: 0 0 38px;
                .about-company-holder { 
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    .logo {
                        padding: 0 5px;
                        width: calc(100% - 402px);
                        .logo-holder { 
                            svg {
                                width: 195px;
                                height: auto;
                            }
                        }
                    }

                    .company-points {
                        padding: 0 5px;
                        width: 402px;
                        .company-points-holder { 
                            .points { 
                                .point { 
                                    margin: 0 0 10px;
                                    .point-holder { 
                                        min-height: 78px;
                                        font-size: 14px;
                                        padding: 10px 20px;
                                        .icon {
                                            width: 40px;
                                            height: 40px;
                                        }

                                        .text {
                                            width: calc(100% - 40px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
