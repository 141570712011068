@media (max-width: $screen-lg) {
    // lg-md view
    .policy-text-block { 
        margin: 0 0 41px;
    }
}

@media (max-width: $screen-md) {
    // md-view
}
