.payment-methods-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 67px;
	.payment-methods-block-holder { 
        display: inline-block;
        width: 100%;
		.payment-methods { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
			.payment { 
                width: 25%;
                height: auto;
                box-sizing: border-box;
                padding: 0 15px;
				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-color: $bg-secondary;
                    box-sizing: border-box;
                    padding: 30px;
                    color: $gray-base;
					.icon { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 41px;
						.icon-holder { 
                            width: 60px;
                            height: 60px;
							svg { 
                                width: 100%;
                                height: auto;
                                color: $brand-primary;
							}
						}
					}

					.text { 
                        display: inline-block;
                        width: 100%;
						.text-holder { 
                            display: inline-block;
                            width: 100%;
							.heading { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 9px;
								h4, .h4 { 

								}
							}

							.desc { 
                                display: inline-block;
                                width: 100%;
                                color: $gray;
                                font-size: 14px;
                                line-height: 140%;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/payment_methods";
@import "../../media/mobile/includes/common/payment_methods";
