@media (max-width: $screen-sm) {
    // sm-view
    .reviews-block {
        margin: 0 0 48px;
        .reviews-block-holder { 
            .heading { 
            }

            .reviews-list { 
                .reviews-list-holder { 
                    width: 100%;
                    margin: 0;
                    .review-item { 
                        width: 100%;
                        padding: 0;
                        .review-item-holder { 
                            .author { 
                                margin: 0 0 12px;
                                .image { 
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 20px;
                                }

                                .stats { 
                                    .name { 
                                        font-size: 14px;
                                    }

                                    .score { 
                                        .stars { 
                                            .star {
                                                width: 13.5px;
                                            }
                                        }
                                    }
                                }
                            }

                            .review-text { 
                                .text {
                                    -webkit-line-clamp: 12;
                                    line-clamp: 12;
                                    p {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .actions { 
                display: inline-block;
                .action {
                    width: 100%;
                    .btn {
                        width: 100%;
                    }
                }

                .pagination-container { 
                    width: 100%;
                    margin: 40px 0 0;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
