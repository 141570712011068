.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 27px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin-right: 0;
  color: #000;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #D7D7D7;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #CF2127;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  position: relative;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 15px;
  border-left: 1px solid #D7D7D7;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-2px);
}

@media (max-width: 1199px) {
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 10px;
    margin-right: 1px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
    height: 10px;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.common-heading h2, .common-heading .h2 {
  float: left;
  max-width: 80%;
}
.common-heading .action {
  float: right;
  max-width: 20%;
  text-align: right;
  padding: 11px 0 0;
}
.common-heading .action a {
  float: right;
}
.common-heading .action a .text {
  float: left;
}
.common-heading .action a .icon {
  float: left;
  width: 17px;
  height: 12px;
  margin-left: 9px;
  padding: 3px 0 0;
}

.slider-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 40px;
}
.slider-heading .controlls {
  margin-right: -7.5px;
}
.slider-heading .controlls .prev, .slider-heading .controlls .next {
  float: left;
  margin: 0 7.5px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: none;
}
.slider-heading .controlls .next {
  float: left;
}
@media (max-width: 1199px) {
  .common-heading {
    margin: 0 0 28px;
  }
  .common-heading .action {
    padding: 7px 0 0;
  }
  .common-heading .action a .icon {
    padding: 2px 0 0;
  }
  .slider-heading {
    margin: 0 0 30px;
  }
}
@media (max-width: 767px) {
  .common-heading h2, .common-heading .h2 {
    max-width: 65%;
  }
  .common-heading .action {
    max-width: 35%;
    font-size: 12px;
    line-height: 120%;
    padding: 5px 0 0;
  }
  .common-heading .action a .icon {
    margin-left: 6px;
  }
  .common-heading .action a .icon svg {
    width: 12px;
    height: auto;
  }
}
.about-company-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 60px;
}
.about-company-block .about-company-block-holder {
  display: inline-block;
  width: 100%;
}
.about-company-block .about-company-block-holder .about-company {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder {
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .logo {
  display: inline-block;
  width: calc(100% - 500px);
  height: auto;
  box-sizing: border-box;
  padding: 0 15px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .logo .logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #F2F5F7;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points {
  width: 500px;
  box-sizing: border-box;
  padding: 0 15px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder {
  display: inline-block;
  width: 100%;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points {
  display: inline-block;
  width: 100%;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point:last-of-type {
  margin: 0;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 110px;
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: #F2F5F7;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder .icon {
  width: 60px;
  height: 60px;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder .icon svg {
  width: 100%;
  height: auto;
}
.about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder .text {
  width: calc(100% - 60px);
  box-sizing: border-box;
  padding-left: 20px;
}
.about-company-block .about-company-block-holder .text-block .text-block-holder {
  padding: 0;
}
@media (max-width: 1469px) {
  .about-company-block {
    margin: 0 0 40px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .logo {
    padding: 0 10px;
    width: calc(100% - 565px);
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points {
    padding: 0 10px;
    width: 565px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point {
    margin: 0 0 20px;
  }
}
@media (max-width: 1199px) {
  .about-company-block {
    margin: 0 0 40px;
  }
  .about-company-block .about-company-block-holder .about-company {
    margin: 0 0 38px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .logo {
    padding: 0 5px;
    width: calc(100% - 402px);
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .logo .logo-holder svg {
    width: 195px;
    height: auto;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points {
    padding: 0 5px;
    width: 402px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point {
    margin: 0 0 10px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder {
    min-height: 78px;
    font-size: 14px;
    padding: 10px 20px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder .icon {
    width: 40px;
    height: 40px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder .text {
    width: calc(100% - 40px);
  }
}
@media (max-width: 767px) {
  .about-company-block {
    margin: 0 0 38px;
  }
  .about-company-block .about-company-block-holder .about-company {
    margin: 0 0 27px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder {
    display: inline-block;
    width: 100%;
    margin-left: 0;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .logo {
    padding: 0;
    width: 100%;
    height: 84vw;
    margin: 0 0 10px;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .logo .logo-holder svg {
    width: 195px;
    height: auto;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points {
    padding: 0;
    width: 100%;
  }
  .about-company-block .about-company-block-holder .about-company .about-company-holder .company-points .company-points-holder .points .point .point-holder {
    font-size: 12px;
    min-height: 83px;
  }
}
.reviews-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 64px;
}
.reviews-block .reviews-block-holder {
  display: inline-block;
  width: 100%;
}
.reviews-block .reviews-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 38px;
}
.reviews-block .reviews-block-holder .reviews-list {
  display: inline-block;
  width: 100%;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder {
  display: inline-block;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item {
  width: 33.333%;
  float: left;
  box-sizing: border-box;
  padding: 0 15px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  background-color: #F2F5F7;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 17px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 28px;
  background-color: #CF2127;
  color: white;
  line-height: 65px;
  text-align: center;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats {
  width: calc(100% - 65px);
  box-sizing: border-box;
  padding-left: 15px;
  margin: 4px 0 0;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .name {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  margin: 0 0 5px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score {
  width: 100%;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars {
  width: 100%;
  margin-left: -1px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-1 .star:first-of-type {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-2 .star:nth-of-type(1),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-2 .star:nth-of-type(2) {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-3 .star:nth-of-type(1),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-3 .star:nth-of-type(2),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-3 .star:nth-of-type(3) {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-4 .star:nth-of-type(1),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-4 .star:nth-of-type(2),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-4 .star:nth-of-type(3),
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-4 .star:nth-of-type(4) {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars.stars-5 .star {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars .star {
  float: left;
  width: 18px;
  height: 17px;
  margin: 0 1.5px;
  color: #D7D7D7;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars .star svg {
  width: 100%;
  height: auto;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text {
  display: inline-block;
  width: 100%;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text {
  overflow: hidden;
  width: 100%;
  height: 281px;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  line-clamp: 15;
  box-orient: vertical;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text p {
  color: #979797;
  font-size: 14px;
  line-height: 143%;
  font-weight: 400;
  margin: 0;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text p:not(:last-of-type) {
  margin: 0 0 17px;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .action {
  display: inline-block;
  width: 100%;
  margin: 28px 0 0;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .action a {
  color: #979797;
}
.reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .action a:hover {
  color: #CF2127;
}
.reviews-block .reviews-block-holder .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 41px 0 0;
}
.reviews-block .reviews-block-holder .actions .action .btn {
  width: 280px;
  text-align: center;
}
.reviews-block .reviews-block-holder .actions .pagination-container .pagination {
  margin: 0;
}

@media (max-width: 1469px) {
  .reviews-block {
    margin: 0 0 59px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item {
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  .reviews-block {
    margin: 0 0 59px;
  }
  .reviews-block .reviews-block-holder .heading {
    margin: 0 0 29px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item {
    width: 50%;
    padding: 0 5px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder {
    padding: 25px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .name {
    font-size: 16px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text {
    height: 173px;
    -webkit-line-clamp: 10;
    line-clamp: 10;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text p {
    font-size: 12px;
  }
  .reviews-block .reviews-block-holder .actions {
    margin: 31px 0 0;
  }
}
@media (max-width: 767px) {
  .reviews-block {
    margin: 0 0 48px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder {
    width: 100%;
    margin: 0;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item {
    width: 100%;
    padding: 0;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author {
    margin: 0 0 12px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .image {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .name {
    font-size: 14px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .author .stats .score .stars .star {
    width: 13.5px;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text {
    -webkit-line-clamp: 12;
    line-clamp: 12;
  }
  .reviews-block .reviews-block-holder .reviews-list .reviews-list-holder .review-item .review-item-holder .review-text .text p {
    font-size: 10px;
  }
  .reviews-block .reviews-block-holder .actions {
    display: inline-block;
  }
  .reviews-block .reviews-block-holder .actions .action {
    width: 100%;
  }
  .reviews-block .reviews-block-holder .actions .action .btn {
    width: 100%;
  }
  .reviews-block .reviews-block-holder .actions .pagination-container {
    width: 100%;
    margin: 40px 0 0;
  }
}
.news-detail-block {
  display: inline-block;
  width: 100%;
  margin: 15px 0 52px;
}
.news-detail-block .news-detail-block-holder {
  display: inline-block;
  width: 100%;
}
.news-detail-block .news-detail-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 43px;
}
.news-detail-block .news-detail-block-holder > .date {
  display: inline-block;
  width: 100%;
  color: #979797;
  margin: 0 0 22px;
}
.news-detail-block .news-detail-block-holder .news-main-image {
  display: inline-block;
  width: 100%;
  height: 540px;
  margin: 0 0 40px;
}
.news-detail-block .news-detail-block-holder .news-main-image .news-main-image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.news-detail-block .news-detail-block-holder .news-main-image .news-main-image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-detail {
  display: inline-block;
  width: 100%;
}
.news-detail .news-detail-holder {
  display: inline-block;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.news-detail .news-detail-holder > .text {
  float: left;
  width: calc(100% - 625px);
  box-sizing: border-box;
  padding: 0 15px;
}
.news-detail .news-detail-holder > .text > .text-holder {
  display: inline-block;
  width: 100%;
}
.news-detail .news-detail-holder > .text > .text-holder h3, .news-detail .news-detail-holder > .text > .text-holder .h3 {
  margin: 0 0 21px;
}
.news-detail .news-detail-holder > .text > .text-holder p {
  color: #979797;
}
.news-detail .news-detail-holder > .text > .text-holder p strong {
  font-weight: 500;
}
.news-detail .news-detail-holder > .text > .text-holder p.accent {
  color: #000;
}
.news-detail .news-detail-holder > .text > .text-holder ul, .news-detail .news-detail-holder > .text > .text-holder ol {
  color: #979797;
}
.news-detail .news-detail-holder > .text > .text-holder hr {
  border: none;
  margin: 14px 0 27px;
}
.news-detail .news-detail-holder > .text > .text-holder img {
  width: 100%;
  height: auto;
}
.news-detail .news-detail-holder .side-bar {
  float: left;
  width: 625px;
  box-sizing: border-box;
  padding: 0 15px;
}
.news-detail .news-detail-holder .side-bar .side-bar-holder {
  display: inline-block;
  width: 100%;
}

.other-news {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.other-news .other-news-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 27px 30px 28px;
}
.other-news .other-news-holder .heading {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 0 0 11px;
  margin: 0 0 32px;
}
.other-news .other-news-holder .heading:after {
  content: "";
  display: inline-block;
  width: 94px;
  height: 0;
  border-top: 1px solid #CF2127;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.other-news .other-news-holder .news-list {
  display: inline-block;
  width: 100%;
}
.other-news .other-news-holder .news-list .news-heading-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 23px;
}
.other-news .other-news-holder .news-list .news-heading-item:last-of-type {
  margin: 0;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder {
  display: inline-block;
  width: 100%;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 14px;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a {
  color: #000;
  display: inline-block;
  position: relative;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a .icon {
  position: absolute;
  right: -10px;
  top: 41%;
  transform: translateX(-50%);
  z-index: 1;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a .icon svg {
  width: 17px;
  height: 11px;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a:hover {
  color: #CF2127;
}
.other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .date {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #979797;
}

.socials {
  display: inline-block;
  width: 100%;
}
.socials .socials-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
.socials .socials-holder .text {
  max-width: 55%;
}
.socials .socials-holder .text .text-holder .heading {
  margin: 0 0 12px;
}
.socials .socials-holder .text .text-holder .note {
  color: #979797;
}
.socials .socials-holder .links {
  max-width: 45%;
}
.socials .socials-holder .links .links-holder {
  margin: 0 -10px;
}
.socials .socials-holder .links .links-holder .link {
  width: 45px;
  height: 45px;
  float: left;
  margin: 0 10px;
}
.socials .socials-holder .links .links-holder .link a {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #000;
}
.socials .socials-holder .links .links-holder .link a:hover {
  color: #CF2127;
}
.socials .socials-holder .links .links-holder .link a svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1469px) {
  .news-detail .news-detail-holder {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .news-detail .news-detail-holder > .text {
    width: calc(100% - 471px);
    padding: 0 10px;
  }
  .news-detail .news-detail-holder .side-bar {
    width: 471px;
    padding: 0 10px;
  }
  .other-news .other-news-holder {
    padding: 30px 30px 30px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name {
    margin: 0 0 7px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
    height: 25px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a .icon {
    top: 21%;
  }
  .socials .socials-holder .text {
    max-width: 75%;
  }
  .socials .socials-holder .links {
    max-width: 25%;
  }
  .socials .socials-holder .links .links-holder .link {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 1199px) {
  .news-detail-block .news-detail-block-holder .heading {
    margin: 0 0 32px;
  }
  .news-detail-block .news-detail-block-holder .news-main-image {
    height: 400px;
    margin: 0 0 32px;
  }
  .news-detail .news-detail-holder {
    width: 100%;
    margin: 0;
  }
  .news-detail .news-detail-holder > .text {
    width: 100%;
    padding: 0;
    margin: 0 0 7px;
  }
  .news-detail .news-detail-holder > .text > .text-holder hr {
    margin: 10px 0 5px;
  }
  .news-detail .news-detail-holder > .text > .text-holder img {
    margin: 0 0 13px;
  }
  .news-detail .news-detail-holder .side-bar {
    width: 100%;
    padding: 0;
  }
  .other-news {
    margin: 0 0 22px;
  }
  .other-news .other-news-holder .news-list .news-heading-item {
    margin: 0 0 22px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name {
    font-size: 14px;
    margin: 0 0 9px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a {
    height: 19px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .date {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .news-detail .news-detail-holder > .text {
    margin: 0 0 18px;
  }
  .other-news {
    background: none;
    margin: 0 0 41px;
  }
  .other-news .other-news-holder {
    padding: 0;
  }
  .other-news .other-news-holder .heading {
    margin: 0 0 22px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name {
    font-size: 12px;
    margin: 0 0 4px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a {
    height: 16px;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .name a .icon {
    top: 9%;
  }
  .other-news .other-news-holder .news-list .news-heading-item .news-heading-item-holder .date {
    font-size: 12px;
  }
  .socials {
    background-color: transparent;
  }
  .socials .socials-holder {
    padding: 0;
  }
  .socials .socials-holder .text .text-holder .heading {
    margin: 0 0 8px;
  }
  .socials .socials-holder .links .links-holder {
    margin: 0 -7.5px;
  }
  .socials .socials-holder .links .links-holder .link {
    margin: 0 7.5px;
    width: 24px;
    height: 24px;
  }
}
.payment-methods-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 67px;
}
.payment-methods-block .payment-methods-block-holder {
  display: inline-block;
  width: 100%;
}
.payment-methods-block .payment-methods-block-holder .payment-methods {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment {
  width: 25%;
  height: auto;
  box-sizing: border-box;
  padding: 0 15px;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #F2F5F7;
  box-sizing: border-box;
  padding: 30px;
  color: #000;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon {
  display: inline-block;
  width: 100%;
  margin: 0 0 41px;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon .icon-holder {
  width: 60px;
  height: 60px;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon .icon-holder svg {
  width: 100%;
  height: auto;
  color: #CF2127;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text {
  display: inline-block;
  width: 100%;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder {
  display: inline-block;
  width: 100%;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 9px;
}
.payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .desc {
  display: inline-block;
  width: 100%;
  color: #979797;
  font-size: 14px;
  line-height: 140%;
}

@media (max-width: 1469px) {
  .payment-methods-block {
    margin: 0 0 61px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment {
    width: 33.333%;
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  .payment-methods-block {
    margin: 0 0 61px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods {
    width: calc(74.5% + 10px);
    margin-left: -5px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment {
    width: 50%;
    padding: 0 5px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a {
    padding: 25px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon {
    margin: 0 0 40px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon .icon-holder {
    width: 45px;
    height: 45px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .heading {
    margin: 0 0 25px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .desc {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .payment-methods-block {
    margin: 0 0 44px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods {
    width: 100%;
    margin: 0;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment {
    width: 100%;
    padding: 0;
    margin: 0 0 10px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon {
    margin: 0 0 20px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .icon .icon-holder {
    width: 35px;
    height: 35px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .heading {
    margin: 0 0 9px;
  }
  .payment-methods-block .payment-methods-block-holder .payment-methods .payment a .text .text-holder .desc {
    font-size: 10px;
  }
}
.contacts-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 32px;
}
.contacts-block .contacts-block-holder {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .map {
  display: inline-block;
  width: 100%;
  height: 540px;
  margin: 0 0 61px;
}
.contacts-block .contacts-block-holder .map .map-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.contacts-block .contacts-block-holder .map .map-holder > img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contacts-block .contacts-block-holder .contacts-data {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 0 15px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 38px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading a {
  display: flex;
  align-items: center;
  color: #000;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading a:hover {
  color: #CF2127;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading a .icon {
  width: 18px;
  height: 18px;
  margin-right: 11px;
  color: #CF2127;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading a .icon svg {
  width: 100%;
  height: auto;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address {
  display: inline-block;
  width: 100%;
  margin: 0 0 29px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 12px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name a {
  color: #979797;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name a:hover {
  color: #CF2127;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 18px;
  line-height: 120%;
  font-weight: 500;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a:hover {
  color: #CF2127;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a .icon {
  width: 18px;
  height: 18px;
  color: #CF2127;
  margin-right: 10px;
}
.contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a .icon svg {
  width: 100%;
  height: auto;
}
@media (max-width: 1469px) {
  .contacts-block {
    margin: 0 0 28px;
  }
  .contacts-block .contacts-block-holder .map {
    margin: 0 0 51px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place {
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  .contacts-block {
    margin: 0 0 20px;
  }
  .contacts-block .contacts-block-holder .map {
    height: 400px;
    margin: 0 0 30px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place {
    width: 33.333%;
    padding: 0 5px;
    margin: 0 0 14px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading {
    margin: 0 0 27px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address {
    margin: 0 0 17px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name {
    margin: 0 0 9px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name a {
    font-size: 12px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .contacts-block {
    margin: 0 0 9px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places {
    width: 100%;
    margin: 0;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place {
    width: 100%;
    padding: 0;
    margin: 0 0 23px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .heading {
    margin: 0 0 16px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address {
    margin: 0 0 17px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name {
    margin: 0 0 6px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .name a {
    font-size: 10px;
  }
  .contacts-block .contacts-block-holder .contacts-data .contacts-data-holder .places .place .place-holder .address .contacts-info .info a {
    font-size: 14px;
  }
}
.policy-text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 48px;
}
.policy-text-block .policy-text-block-holder {
  display: inline-block;
  width: 100%;
  color: #979797;
}
@media (max-width: 1469px) {
  .policy-text-block {
    margin: 0 0 41px;
  }
}
@media (max-width: 767px) {
  .policy-text-block {
    margin: 0 0 30px;
  }
}
.not-found-page {
  display: inline-block;
  width: 100%;
  margin: 0 0 38px;
}
.not-found-page .not-found-page-holder {
  display: inline-block;
  width: 91.5%;
  padding: 41px 0 0;
}
.not-found-page .not-found-page-holder .desc {
  width: calc(100% - 710px);
  float: left;
  padding: 113px 0 0;
}
.not-found-page .not-found-page-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.not-found-page .not-found-page-holder .desc .desc-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 29px;
}
.not-found-page .not-found-page-holder .desc .desc-holder .note {
  display: inline-block;
  width: 70%;
  color: #979797;
  margin: 0 0 16px;
}
.not-found-page .not-found-page-holder .desc .desc-holder .note p {
  margin: 0 0 15px;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links {
  display: inline-block;
  width: 100%;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link {
  display: inline-block;
  width: 100%;
  margin: 0 0 8px;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link a {
  color: #000;
  font-size: 18px;
  line-height: 120%;
  font-weight: 500;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link a:hover {
  color: #CF2127;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link a .text {
  float: left;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link a .icon {
  float: left;
  margin-left: 10px;
}
.not-found-page .not-found-page-holder .desc .desc-holder .links .link a .icon svg {
  width: 17px;
  height: 11px;
  vertical-align: middle;
}
.not-found-page .not-found-page-holder .four-o-four {
  width: 710px;
  float: right;
}
.not-found-page .not-found-page-holder .four-o-four .four-o-four-holder {
  display: inline-block;
  width: 100%;
}
.not-found-page .not-found-page-holder .four-o-four .four-o-four-holder .image {
  display: inline-block;
  width: 100%;
}
.not-found-page .not-found-page-holder .four-o-four .four-o-four-holder .image svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1469px) {
  .not-found-page .not-found-page-holder {
    width: 100%;
  }
  .not-found-page .not-found-page-holder .desc {
    width: calc(100% - 477px);
    padding: 32px 0 0;
  }
  .not-found-page .not-found-page-holder .four-o-four {
    width: 477px;
  }
}
@media (max-width: 1199px) {
  .not-found-page .not-found-page-holder .desc {
    width: calc(100% - 392px);
    padding: 8px 0 0;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .note {
    width: 96%;
    margin: 0 0 20px;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .links .link a {
    font-size: 16px;
  }
  .not-found-page .not-found-page-holder .four-o-four {
    width: 392px;
  }
}
@media (max-width: 767px) {
  .not-found-page {
    margin: 0 0 30px;
  }
  .not-found-page .not-found-page-holder {
    padding: 38px 0 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .not-found-page .not-found-page-holder .desc {
    width: 100%;
    padding: 42px 0 0;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .heading {
    margin: 0 0 21px;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .note {
    width: 100%;
    margin: 0 0 6px;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .note p {
    margin: 0 0 17px;
  }
  .not-found-page .not-found-page-holder .desc .desc-holder .links .link a {
    font-size: 14px;
  }
  .not-found-page .not-found-page-holder .four-o-four {
    width: 100%;
  }
}
.news-block {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  padding: 0 0 38px;
}
.news-block .news-block-holder {
  display: inline-block;
  width: 100%;
}
.news-block .news-block-holder .news-list-block {
  padding: 38px 40px 33px;
}

.news-list-block.standalone {
  margin: 0 0 65px;
}
.news-list-block.standalone .news-list-block-holder .news-headings-list {
  padding-left: 71px;
}

.news-list-block {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
}
.news-list-block .news-list-block-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .spotlight {
  width: 34%;
  float: left;
}
.news-list-block .news-list-block-holder .news-headings-list {
  width: 66%;
  float: left;
  box-sizing: border-box;
  padding-left: 98px;
  padding-top: 33px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item:last-of-type {
  margin: 0;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 9px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name a {
  color: #000;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name a:hover {
  color: #CF2127;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #979797;
}

.news-item {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.news-item .news-item-holder .news-info {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  min-height: 294px;
}
.news-item .news-item-holder .news-info .date {
  text-align: left;
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 10px;
}
.news-item .news-item-holder .news-info .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  margin: 0 0 19px;
}
.news-item .news-item-holder .news-info .name a {
  color: #000;
}
.news-item .news-item-holder .news-info .name a:hover {
  color: #CF2127;
}
.news-item .news-item-holder .news-info .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-clamp: 5;
  box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  color: #979797;
}
.news-item .news-item-holder .action {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder .action .btn {
  width: 280px;
  text-align: center;
}

@media (max-width: 1469px) {
  .news-block {
    padding: 0 0 16px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 38px 40px 39px;
  }
  .news-list-block.standalone {
    margin: 0 0 65px;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    padding-left: 31px;
    padding-top: 33px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 45%;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 55%;
    padding-left: 107px;
    padding-top: 35px;
  }
}
@media (max-width: 1199px) {
  .news-block {
    padding: 0 0 32px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 30px 30px 30px;
  }
  .news-list-block.standalone {
    margin: 0 0 50px;
  }
  .news-list-block.standalone .news-list-block-holder .spotlight {
    width: 41%;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    width: 59%;
    padding-left: 59px;
    padding-top: 29px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 38%;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 62%;
    padding-left: 61px;
    padding-top: 30px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 31px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
    font-size: 16px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
    font-size: 14px;
  }
  .news-item .news-item-holder .news-info {
    min-height: 297px;
  }
  .news-item .news-item-holder .news-info .date {
    font-size: 14px;
  }
  .news-item .news-item-holder .news-info .name {
    -webkit-line-clamp: 4;
    line-clamp: 4;
    margin: 0 0 21px;
  }
  .news-item .news-item-holder .news-info .text {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .news-block {
    padding: 0 0 9px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 20px 20px 20px;
  }
  .news-list-block.standalone {
    margin: 0 0 15px;
  }
  .news-list-block.standalone .news-list-block-holder .spotlight {
    width: 100%;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    width: 100%;
    padding-left: 0;
    padding-top: 0;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 32px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 100%;
    margin: 0 0 42px;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 100%;
    padding: 0;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 22px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
    font-size: 14px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
    font-size: 12px;
  }
  .news-item .news-item-holder .news-info {
    min-height: auto;
  }
  .news-item .news-item-holder .news-info .date {
    font-size: 12px;
  }
  .news-item .news-item-holder .news-info .name {
    font-size: 18px;
  }
  .news-item .news-item-holder .news-info .text {
    font-size: 12px;
  }
  .news-item .news-item-holder .action {
    width: 100%;
    margin: 21px 0 0;
  }
  .news-item .news-item-holder .action .btn {
    width: 100%;
    padding: 13px 14px;
  }
}