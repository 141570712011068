@media (max-width: $screen-lg) {
    // lg-md view
    .payment-methods-block { 
        margin: 0 0 61px;
        .payment-methods-block-holder {
            .payment-methods { 
                width: calc(100% + 20px);
                margin-left: -10px;
                .payment { 
                    width: 33.333%;
                    padding: 0 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .payment-methods-block { 
        margin: 0 0 61px;
        .payment-methods-block-holder {
            .payment-methods { 
                width: calc(74.5% + 10px);
                margin-left: -5px;
                .payment { 
                    width: 50%;
                    padding: 0 5px;
                    a {
                        padding: 25px;
                        .icon {
                            margin: 0 0 40px;
                            .icon-holder {
                                width: 45px;
                                height: 45px;
                                svg {
                                    
                                }
                            }
                        }

                        .text {
                            .text-holder {
                                .heading {
                                    margin: 0 0 25px;
                                }

                                .desc {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
