.about-company-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 60px;
	.about-company-block-holder { 
        display: inline-block;
        width: 100%;
		.about-company { 
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
			.about-company-holder { 
                display: flex;
                width: calc(100% + 30px);
                margin-left: -15px;
				.logo { 
                    display: inline-block;
                    width: calc(100% - 500px);
                    height: auto;
                    box-sizing: border-box;
                    padding: 0 15px;
					.logo-holder { 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        background-color: $bg-secondary;
						svg { 

						}
					}
				}

				.company-points { 
                    width: 500px;
                    box-sizing: border-box;
                    padding: 0 15px;
					.company-points-holder { 
                        display: inline-block;
                        width: 100%;
						.points { 
                            display: inline-block;
                            width: 100%;
							.point { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 30px;
                                &:last-of-type {
                                    margin: 0;
                                }
								.point-holder { 
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    min-height: 110px;
                                    padding: 10px 25px;
                                    box-sizing: border-box;
                                    background-color: $bg-secondary;
                                    font-size: 16px;
                                    line-height: 120%;
                                    font-weight: 500;
									.icon { 
                                        width: 60px;
                                        height: 60px;
										svg { 
                                            width: 100%;
                                            height: auto;
										}
									}

									.text { 
                                        width: calc(100% - 60px);
                                        box-sizing: border-box;
                                        padding-left: 20px;
										br { 

										}
									}
								}
							}
						}
					}
				}
			}
		}

		.text-block { 
            
			.text-block-holder { 
                padding: 0;
				p { 

				}

				ul { 

					li { 

					}
				}

				ol { 

					li { 

					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/about";
@import "../../media/mobile/includes/common/about";
