@media (max-width: $screen-lg) {
    // lg-md view
    .reviews-block { 
        margin: 0 0 59px;
        .reviews-block-holder { 
            .heading { 

            }

            .reviews-list { 
                .reviews-list-holder { 
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    .review-item { 
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .reviews-block { 
        margin: 0 0 59px;
        .reviews-block-holder { 
            .heading { 
                margin: 0 0 29px;
            }

            .reviews-list { 
                .reviews-list-holder { 
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    .review-item { 
                        width: 50%;
                        padding: 0 5px;
                        .review-item-holder { 
                            padding: 25px;
                            .author { 
                                .image {

                                }

                                .stats { 
                                    .name {
                                        font-size: 16px;
                                    }

                                    .score {

                                    }
                                }
                            }

                            .review-text { 
                                .text {
                                    height: 173px;
                                    -webkit-line-clamp: 10;
                                    line-clamp: 10;
                                    p {
                                        font-size: 12px;
                                    }
                                }
                            }

                            .action {

                            }
                        }
                    }
                }
            }

            .actions { 
                margin: 31px 0 0;
            }
        }
    }
}
