.policy-text-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 48px;
	.policy-text-block-holder { 
        display: inline-block;
        width: 100%;
        color: $gray;
		p { 

		}

		.dashes { 

			li { 

			}
		}
	}
}

@import "../../media/tablet/includes/common/policy";
@import "../../media/mobile/includes/common/policy";

